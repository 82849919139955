import styled from "styled-components";

export const Heading = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 1.5rem 0 1.5rem;
  }

  &:after {
    content: "";
    display: block;
    border-bottom: 5px solid #11d6f0;
    width: 57px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
  }
`;

export const PageHeading = styled.h1`
  font-size: 3rem;
  text-transform: uppercase;
  color: #444444;
  font-weight: 700;

  &:before {
    content: "";

    display: inline-block;

    width: 60px;

    height: 15px;

    border-color: #11d6f0;

    border-style: solid;

    border-width: 5px 5px 0 5px;

    position: absolute;

    top: -20px;

    left: 0;

    right: 0;

    margin: auto;
  }
`;

export const PageDescription = styled.p`
  font-size: 1.3rem;
  color: #444444;
`;
