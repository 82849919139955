import styled from "styled-components";

export const IBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  margin: 0 0 20px;
  background: #fbfbfb;
  :before {
    border-color: #11d6f0 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #11d6f0;
    left: 0;
    top: 0;
  }
  :after {
    border-color: rgba(0, 0, 0, 0) #11d6f0 #11d6f0 rgba(0, 0, 0, 0);
    right: 0;
    bottom: 0;
  }
  :before,
  :after {
    border-style: solid;
    border-width: 2px;
    content: "";
    display: block;
    height: 50px;
    position: absolute;
    width: 50px;
  }

  h2 {
    margin: 0 0 15px 0;
  }

  ul {
    display: block;
    text-align: center;
  }

  li {
    display: inline-block;
    min-width: 160px;
    font-size: 1.6rem;
    padding: 10px 20px;
    margin: 10px;
    color: #ffffff;
    background: rgb(67, 173, 218) none repeat scroll 0% 0%;
  }
  strong {
    font-weight: bold;
  }
`;
