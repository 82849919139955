import React from "react";
import ReactPlayer from "react-player";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import PageHeader from "../components/PageHeader/PageHeader";
import InfoBox from "../components/InfoBox";
import { PageContainer, PageContent, Section } from "../styles";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Footer from "../components/Footer";
import styled from "styled-components";

export default function About() {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "grace.jpg" }) {
        sharp: childImageSharp {
          fixed(width: 300, height: 500, quality: 85) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>Videos - Peace By Piece CBT</title>
        <meta
          name="description"
          content="I like to work with you as an individual, not with you as a label,
            so that you are looking at yourself as a unique person - not a
            diagnosis."
        />
      </Helmet>
      <PageContainer>
        <PageHeader title="Grace Lewis" description="Facebook Video Sessions" />
      </PageContainer>
      <div className="container">
        <div className="row">
          <div className="col col-lg-12">
            <PageContent>
              <Section>
                <Video>
                  <InfoBox>
                    <ReactPlayer
                      controls={true}
                      url="https://www.facebook.com/PeaceByPieceCBT/videos/695707801286628"
                    />
                  </InfoBox>
                </Video>
              </Section>
            </PageContent>
          </div>
        </div>
      </div>
      <div style={{ background: "rgb(30, 36, 54)" }}>
        <Footer contactForm={true} />
      </div>
    </Layout>
  );
}

const AboutSection = styled.div`
display: flex;
flex-direction: column;
align-items: center;
}
`;

const TextSection = styled.div`
  @media (min-width: 900px) {
    float: right;
  }
`;

const Video = styled.div`
  display: flex;
  justify-content: center;
`;
