import React from "react";
import { Heading, PageHeading, PageDescription } from "./styles";

export default function PageHeader(props) {
  return (
    <Heading>
      <PageHeading>{props.title}</PageHeading>
      <PageDescription>{props.description}</PageDescription>
    </Heading>
  );
}
